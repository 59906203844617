import React from 'react';
import { Container, Row, Col } from 'react-bootstrap'

function OurMission() {

    return (
        <>
            <section className='our-mission'>
                <Container>
                    <Row className='row-cols-lg-2 align-items-center row-cols-1'>
                        <Col>
                          <img src="assets/images/vision-mission.svg" alt="vision-mission" className='img-fluid' />
                        </Col>
                        <Col>
                            <h3>Bridging <span>Businesses and Consumers</span></h3>
                            <p>When businesses form impactful connections, they thrive. Our purpose is to facilitate these vital links.</p>
                            <p>We synchronize every sector to better on consumers with the premiem SAAS based Software tools. We enable every team member to access a unified truth, integrating collaborative tools that foster critical discussions and decisions in their natural workflow. And through HRMS, we empower organizations to discover profound insights and act on data to better cater to their customers.</p>
                        </Col>
                        <Col>

                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
}

export default OurMission