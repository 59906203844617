import React from 'react';
import {Link} from 'react-router-dom';
import {Container, Button} from 'react-bootstrap';


function HomeHero() {
  return (
    <>
    <section className='home-hero'>
        <Container>
            <h1 data-aos="fade-up">Platform Built For <span>StartUps</span> – A Modern Way Of Working</h1>
            <p data-aos="fade-up">FREE to use products for StartUps 365 Days Free Trial</p>
            <div className='home-btn'>
              <Link  to="/contact"  data-aos="zoom-in">Start Free trial</Link>
            </div>
        </Container>
    </section>
    <section className='home-image'>
       <Container>
          <img src="assets/images/laptop.png" alt="laptop.png" className='img-fluid' />
       </Container>
    </section>
    </>
  )
}

export default HomeHero