import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const ProductHome = ({ title, description, buttonText, buttonPhone, imageUrl }) => {
    return (
        <>
            <section className='product-home'>
                <Container>
                    <Row className='justify-content-center align-items-center'>
                        <Col xl={10}>
                            <h1>{title}</h1>
                            <p>{description}</p>
                            <a href={`tel:${buttonPhone}`} className='btn btn-outline-light rounded-pill'>{buttonText}</a>
                            {imageUrl && <img src={imageUrl} alt="Product visual" className="mt-3" style={{ maxWidth: '100%', height: 'auto' }} />}
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default ProductHome;
