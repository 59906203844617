import React from 'react';
import {Link}from 'react-router-dom'
import { Container, Breadcrumb } from 'react-bootstrap'

function ContactHome() {
    return (
        <>
            <section className='contact-us'>
                <Container>
                    <h1>Contact</h1>
                    <Breadcrumb className='app-breadcrumb'>
                        <Breadcrumb.Item>
                           <Link to="/">Home</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>Contact</Breadcrumb.Item>
                    </Breadcrumb>
                </Container>
            </section>
        </>
    )
}

export default ContactHome