import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

function HrmsFeatures() {
    const hrmsFeatures = [
        { id: 1, title: 'Employee', iconSrc: 'assets/images/feature-icons/icon-30.png' },
        { id: 2, title: 'Department', iconSrc: 'assets/images/feature-icons/icon-31.png' },
        { id: 3, title: 'Awards', iconSrc: 'assets/images/feature-icons/icon-32.png' },
        { id: 4, title: 'Expense', iconSrc: 'assets/images/feature-icons/icon-33.png' },
        { id: 5, title: 'Holidays', iconSrc: 'assets/images/feature-icons/icon-34.png' },
        { id: 6, title: 'Attendance', iconSrc: 'assets/images/feature-icons/icon-35.png' },
        { id: 7, title: 'Employee Management', iconSrc: 'assets/images/feature-icons/icon-36.png' },
        { id: 8, title: 'IP Based Attendance/Generate', iconSrc: 'assets/images/feature-icons/icon-37.png' },
        { id: 9, title: 'Calendar', iconSrc: 'assets/images/feature-icons/icon-38.png' },
        { id: 10, title: 'Project Management', iconSrc: 'assets/images/feature-icons/icon-39.png' },
        { id: 11, title: 'Training & Recruitment', iconSrc: 'assets/images/feature-icons/icon-40.png' },
        { id: 12, title: 'Accounting/Finance', iconSrc: 'assets/images/feature-icons/icon-41.png' },
        { id: 13, title: 'Support Ticket', iconSrc: 'assets/images/feature-icons/icon-42.png' },
        { id: 14, title: 'Events & Meetings', iconSrc: 'assets/images/feature-icons/icon-43.png' },
        { id: 15, title: 'User Role Permission', iconSrc: 'assets/images/feature-icons/icon-44.png' },
        { id: 16, title: 'Mobile Friendly', iconSrc: 'assets/images/feature-icons/icon-45.png' },
        { id: 17, title: 'Payment', iconSrc: 'assets/images/feature-icons/icon-46.png' },
      
    ];

    return (
        <>
            <section className='features'>
                <Container>
                    <h2>Our <span>Features</span></h2>
                    <Row className='row-cols-xl-6 g-3 row-cols-2 justify-content-center'>
                        {hrmsFeatures.map(feature => (
                            <Col key={feature.id}>
                                <div className="feature-icon">
                                    <div className='icon'>
                                        <img src={feature.iconSrc} alt="feature-icon"/>
                                    </div>
                                    <div className='card-content'>
                                        <h4>{feature.title}</h4>
                                    </div>
                                </div>
                            </Col>
                        ))}
                    </Row>
                </Container>
            </section>
        </>
    );
}

export default HrmsFeatures;
