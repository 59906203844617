import React from 'react';
import { Helmet } from 'react-helmet';
import HomeHero from '../components/home/HomeHero';
// import Product from '../components/home/ExploreProduct';
import TrustedClient from '../components/home/TrustedClient';
import HomeAbout from '../components/home/HomeAbout';
import ConnectUs from '../components/home/ConnectUs';
import Ats from '../components/home/Ats';
import Aeps from '../components/home/Aeps';
import Hrms from '../components/home/Hrms';
import WhyChoose from '../components/home/WhyChoose'
import Counter from '../components/home/Counter';
import Testimonials from '../components/home/Testimonials';



function Home() {
  return (
    <React.Fragment>
       <Helmet>
         <title>Verified & Tested Startup Marketplace - AblyWorks</title>
         <meta name="description" content="Discover top-tier software solutions for startups, including Applicant Tracking System (ATS), AEPS software, and HR Payroll systems, crafted by expert developers dedicated to driving your business success."></meta>
         <link rel="canonical" href="https://www.ablyworks.com/" />
       </Helmet>
        <HomeHero/>
        <TrustedClient/>
        <Ats/>
        <Aeps/>
        <Hrms/>
        {/* <Product/> */}
        <ConnectUs/>
        <HomeAbout/>
       
        <WhyChoose/>
        <Counter/>
        <Testimonials/>
    </React.Fragment>
  )
}

export default Home