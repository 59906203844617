import React from "react";
import {Container, Row, Col, Button} from 'react-bootstrap'

function NeedGuidance() {

    return (
        <>
            <section className="need-guidance">
                <Container>
                    <Row className="align-items-center">
                        <Col xl={8}>
                           <h3>Need Guidance? We're Here to Help You.</h3>
                           <p>Inquire about AblyWorks products, pricing, implementation, or anything else – our highly skilled representatives are ready to assist.</p>
                        </Col>
                        <Col> 
                          <a href="tel:+91-9873183626" className="btn btn-primary">dial +91-98731 83626</a>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default NeedGuidance