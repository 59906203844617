import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import contentData from '../../TermsData.json'; 

function TermsText() {
    return (
        <>
            <section className='terms-text'>
                <Container>
                    <Row className='justify-content-center'>
                        <Col xl={8}>
                            <Card>
                                <Card.Body>
                                    {contentData.map((section, index) => (
                                        <div key={index}>
                                            <h6 className='card-title' data-aos="fade-up">{section.title}</h6>
                                            <ul>
                                                {section.content.map((item, i) => (
                                                    <li key={i} data-aos="fade-up">{item}</li>
                                                ))}
                                            </ul>
                                        </div>
                                    ))}
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
}
export default TermsText;
