import React, { Fragment } from 'react'
import HrmsHome from '../components/hrms/HrmsHome';
import HrmsFeatures from '../components/hrms/HrmsFeatures';
import HrmsFaq from '../components/hrms/HrmsFaq';
import { Helmet } from 'react-helmet';
import HrmsDetails from '../components/hrms/HrmsDetails';
import AboutHrms from '../components/hrms/AboutHrms';

function Hrms() {
  return (
    <Fragment>
       <Helmet>
         <title>HR Software India | HR Portal| (HRMS) HR Manager Software </title>
         <meta name="description" content="Elevate HR management with our top HR software in India. Streamline employee operations, payroll, with our intuitive HR portal and HRMS."></meta>
         <link rel="canonical" href="https://www.ablyworks.com/" />
       </Helmet>
        <HrmsHome/>
        <HrmsDetails/>
        <AboutHrms/>
        <HrmsFeatures/>
        <HrmsFaq/>
    </Fragment>
  )
}

export default Hrms