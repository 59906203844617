import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom'; 
import Breadcrumb from 'react-bootstrap/Breadcrumb';

function TermsHome() {
    return (
        <>
            <section className='terms-top'>
                <Container>
                    <Row className='justify-content-center text-center'>
                        <Breadcrumb className='app-breadcrumb' data-aos="fade-up">
                            <Breadcrumb.Item>
                                <Link to="/" className='text-light'>Home</Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active className=''>Terms Conditions</Breadcrumb.Item>
                        </Breadcrumb>
                        <Col>
                            <div className='web-heading' data-aos="fade-up">
                                <h2>Terms And Condition</h2>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default TermsHome;
