import React from "react";
import ProductHome from "../aeps/ProductHome";

const HrmsHome = () => {
    const hrmsContent = {
        title: "HRMS – HR Software & Portal",
        description: "Elevate Your HR Operations with Advanced HR Software Solutions and Portals in India. Explore the Latest Offerings in HR Software India to Streamline Processes and Drive Efficiency Throughout Your Organization.",
        buttonText: "Call Now",
        buttonPhone: "+919812345670",
    };

    return(
        <ProductHome {...hrmsContent}/>
    );
};

export default HrmsHome