import React from 'react';
import { Container, Row, Col } from 'react-bootstrap'

function AepsAdharPay() {
  return (
    <>
      <section className='aeps-aadhar'>
        <Container>
          <Row className='gx-5 row-cols-xl-2 row-cols-lg-2 row-cols-md-2 row-cols-1'>
            <Col>
              <h2>AEPS And <span>Aadhaar Pay</span></h2>
              <p>Aadhaar Pay offer convenient banking solutions for customers and merchants by leveraging Aadhaar number and biometric authentication. AEPS facilitates basic banking transactions such as cash withdrawal, balance inquiry, and fund transfer, particularly benefiting rural residents with limited access to bank branches and ATMs. Meanwhile, Aadhaar Pay empowers merchants to accept payments without cards or PINs, particularly advantageous for small merchants lacking infrastructure for other digital payment methods.</p>
              <p>AEPS and Aadhaar Pay are secure payment systems aimed at improving financial inclusion and encouraging a cashless economy. It's crucial to acknowledge that these systems are dependent on the Aadhaar biometric database, which has faced privacy issues and legal battles in India.</p>
            </Col>
            <Col className='text-center'>
              <img src="assets/images/mobile-recharge.png" alt="mobile-recharge.png" />
              <img src="assets/images/mobile-recharge-2.png" alt="mobile-recharge.png" />
              <img src="assets/images/mobile-recharge-3.png" alt="mobile-recharge.png" />
              <img src="assets/images/mobile-recharge-4.png" alt="mobile-recharge.png" />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default AepsAdharPay