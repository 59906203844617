import React from 'react';
import {Link} from 'react-router-dom';
import {Container} from 'react-bootstrap'

function PageNotFound() {
  return (
    <>
     <div className="not-found">
      <Container>
        <img src="assets/img/not-found.png" />
        <div className="web-heading mb-3">
          <h1>Error 404</h1>
          <p>We can’t seem to find the page you’re looking for.</p>
        </div>
        <Link to="/" className="w-auto btn btn-primary cursor-pointer">
          Go back to homepage
        </Link>
      </Container>
    </div>
    </>
  )
}

export default PageNotFound