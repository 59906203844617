import React from 'react';
import './css/App.css';
import './css/responsive.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Routes, Route } from "react-router-dom";
import AppNavbar from "./components/shared/AppNavbar";
import Home from './pages/home';
import About from './pages/about';
import Ourproduct from './pages/aeps';
import Hrms from './pages/hrsm';
import Contact from './pages/contact';
import Footer from "./components/shared/Footer";
import PageNotFound from "./pages/404-pages";
import Ats from "./pages/ats";
import TermsConditions from './pages/terms-and-conditions';
import PrivacyPolicy from './pages/privacy-policy';
import ThankYou from './pages/thank-you';



function App() {
  return (
    <div className="App">
      <AppNavbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/aeps" element={<Ourproduct />} />
        <Route path="/about" element={<About />} />
        <Route path="/ats" element={<Ats />} />
        <Route path="/hrms" element={<Hrms />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/terms-and-condition" element={<TermsConditions />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="*" element={<PageNotFound />} />
        <Route path="/thank-you" element={< ThankYou/>} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
