import React from 'react';
import { Container, Row, Col } from 'react-bootstrap'

function DomecticMT() {
    return (
        <>
            <section className='domectic-transfer'>
                <Container>
                    <Row className='align-items-center row-cols-xl-2 row-cols-1'>
                        <Col>
                            <img src="assets/images/dmt.png" alt="domectic" className='img-fluid'/>
                        </Col>
                        <Col>
                            <h2><span>Domestic Money </span>Transfer</h2>
                            <p>Bank transfer is one of the most common ways of transferring money domestically. It involves sending money directly from one bank account to another. This can be done through online banking, telephone banking or by visiting a bank branch.</p>
                            <p>Mobile banking is another popular method of domestic money transfer. It allows users to send and receive money using their mobile phones. Users can either use the mobile banking app provided by their bank or through a third-party app such as PayPal, Venmo, or Cash App.</p>
                            <p>Online payment services like PayPal, Google Pay, and others are also commonly used for domestic money transfers. These services allow users to transfer money from one account to another using a linked bank account, debit or credit card.</p>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default DomecticMT