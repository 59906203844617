import React, { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

function ControlledCarousel() {
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };

    const carouselItems = [
        {
            id: 1,
            text: "I am loving free tools from ABLYWorks, Now I do not have to restart the calls after 40 minutes. ABLYTeam offers unlimited free video calls with no charges or capping on minutes.",
            imageSrc: "assets/images/user.png",
            name: "Abhay Kumar",
            role: "CEO, Enable Tree"
        },
        {
            id: 2,
            text: "I started with FREE subscription of ABLYWorks ERP Suite, but later as my business started growing, I am using the paid version. Happy with pricing as well as stable platform. Thanks ABLYWorks Team.",
            imageSrc: "assets/images/user.png",
            name: "Aura Brooks",
            role: "Marketing Director, Owl Eyes"
        },
        {
            id: 3,
            text: "I am impressed with ABLYWorks range of products. So started with HRMS and later also signup for ABLYWorks Business ERP suite. It has all the features which are required to run and automate business functions. Now I am using most of the functionality of ER",
            imageSrc: "assets/images/user.png",
            name: "Aman Katoch",
            role: "CEO & Founder, Hrizen"
        },
        {
            id: 4,
            text: "I signed up for Free tools and later referred to my other friends in Nairobi, All my friends are using ABLYWorks software tools and are happy. I was looking for Free Tools, but could not get anywhere with unlimited time. Here I have 365 days of FREE unlim",
            imageSrc: "assets/images/user.png",
            name: "John Tukuma",
            role: "CTO at Harmony Travels"
        },
        // Add more items as needed
    ];

    return (
        <section className='testimonials'>
            <div className='web-heading'>
                <h2 data-aos="fade-up">Testimonials</h2>
            </div>
            <Container>
                <Carousel controls={false} data-aos="fade-up">
                    {carouselItems.map((item, idx) => (
                        idx % 2 === 0 && (
                            <Carousel.Item key={idx}>
                                <Row className='g-3'>
                                    {carouselItems.slice(idx, idx + 2).map((item) => (
                                        <Col sm={6} key={item.id} className='d-flex'>
                                            <Card>
                                                <Card.Body>
                                                    <Card.Text>{item.text}</Card.Text>
                                                    <div className='testimonials-card'>
                                                        <div>
                                                            <Card.Img
                                                                className="d-block"
                                                                src={item.imageSrc}
                                                                alt="user-image"
                                                            />
                                                        </div>
                                                        <div className='profile'>
                                                            <Card.Title>{item.name}</Card.Title>
                                                            <p>{item.role}</p>
                                                        </div>
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    ))}
                                </Row>
                            </Carousel.Item>
                        )
                    ))}
                </Carousel>
            </Container>
        </section>
    );
}

export default ControlledCarousel;
