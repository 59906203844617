import React from 'react';
import AtsFaq from '../components/ats/AtsFaq'
import AtsHome from '../components/ats/AtsHome';
import AtsFeatures from '../components/ats/AtsFeatures';
import AtsDetails from '../components/ats/AtsDetails';
import { Helmet } from 'react-helmet';
import AtsAbout from '../components/ats/AtsAbout';



function AtsPage(props) {
  return (
    <>
      <Helmet>
        <title>Applicant Tracking System (ATS) Software - Recruiting Software</title>
        <meta name="description" content="- Explore top Applicant Tracking System (ATS) software for efficient hiring. Streamline candidate management & optimize recruitment with the best ATS solutions."></meta>
        <link rel="canonical" href="https://www.ablyworks.com/" />
      </Helmet>
      <AtsHome />
      <AtsDetails />
      <AtsAbout />
      <AtsFeatures />
      <AtsFaq />

    </>
  )
}

export default AtsPage
