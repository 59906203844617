import React from 'react';
import { Col, Container, Row } from 'react-bootstrap'



function AboutHrms() {
    return (
        <>
            <section className='about-hrms py-5'>
                <Container>
                    <Row>
                        <Col xl={6}>
                            <img src="assets/images/hr-min.png" alt="hr-min.png" className='img-fluid rounded-1' />
                        </Col>
                        <Col xl={6}>
                            <h3>About Ablyworks <span>HR Software</span> & Portal</h3>
                            <p>Explore AblyWorks, the prominent provider of cutting-edge HR software and portal solutions in India. Our state-of-the-art Human Resource Information System (HRMS) features user-friendly HR manager software that enables companies to efficiently streamline their HR operations. Covering everything from employee management to payroll processing, our comprehensive HR software solution in India is designed to meet all your HR requirements, facilitating smooth business operations and boosting overall productivity.</p>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className='about-hrms py-5'>
                <Container>
                    <Row>
                        <Col xl={6}>
                            <h3>Explore Comprehensive <span>HR Manager Software</span> Solutions</h3>
                            <p>Discover Our Comprehensive HR Manager Software Solutions. Designed to Streamline Every Aspect of HR Management, from Recruitment to Performance Evaluation, Our Intuitive Tools Empower Your Team to Achieve Optimal Efficiency and Drive Organizational Success.


                                Explore our diverse range of HR Manager software solutions tailored to simplify all HR functions, including recruitment and performance assessments. Our user-friendly tools aim to equip your team with the resources needed to enhance productivity and propel your organization towards success.</p>
                        </Col>
                        <Col xl={6}>
                            <img src="assets/images/hrms.jpg" alt="hr-min.png" className='img-fluid rounded-1' />
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
}

export default AboutHrms