import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

function Counter() {
    const [isVisible, setIsVisible] = useState(false);
    const [counts, setCounts] = useState([
        { count: 0, text: 'Happy Clients' },
        { count: 0, text: 'Successful Projects' },
        { count: 0, text: 'Satisfied Customers' },
        { count: 0, text: 'Year in Buniness' } // New field
    ]);

    const counterRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setIsVisible(true);
                        observer.unobserve(entry.target);
                    }
                });
            },
            { threshold: 0.5 }
        );

        if (counterRef.current) {
            observer.observe(counterRef.current);
        }

        return () => {
            if (counterRef.current) {
                observer.unobserve(counterRef.current);
            }
        };
    }, []);

    useEffect(() => {
        if (isVisible) {
            const animateNumbers = () => {
                const targetCounts = [
                    { count: 1000, text: 'Happy Clients' },
                    { count: 200, text: 'Successful Projects' },
                    { count: 3000, text: 'Satisfied Customers' },
                    { count: 10, text: 'New Field' } // New field values
                ];

                const step = () => {
                    setCounts(prevCounts => {
                        return prevCounts.map((counter, index) => {
                            const targetCount = targetCounts[index].count;
                            const increment = Math.ceil(targetCount / 100); // Adjust animation speed here
                            const newCount = Math.min(counter.count + increment, targetCount);
                            return { ...counter, count: newCount };
                        });
                    });

                    // Stop animation when all counts reach their target
                    if (counts.every((counter, index) => counter.count === targetCounts[index].count)) {
                        cancelAnimationFrame(animationFrameId);
                    } else {
                        animationFrameId = requestAnimationFrame(step);
                    }
                };

                let animationFrameId = requestAnimationFrame(step);
            };

            animateNumbers();
        }
    }, [isVisible]);

    return (
        <section className='counter counter-bg' ref={counterRef}>
            <Container>
                <Row className='g-xl-0 g-3 row-cols-1 row-cols-xl-4 row-cols-lg-4 row-cols-md-4'>
                    {counts.map((counter, index) => (
                        <Col key={index}>
                            <ul>
                                <li><img src={`assets/images/count-1-${index + 1}.png`} alt="client" /></li>
                                <li>
                                    <h3>{counter.count}+</h3>
                                    <p>{counter.text}</p>
                                </li>
                            </ul>
                        </Col>
                    ))}
                </Row>
            </Container>
        </section>
    );
}

export default Counter;
