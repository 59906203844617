import React from 'react';
import { Helmet } from 'react-helmet';
import ContactHome from '../components/contact/ContactHome';
import ContactForm from '../components/contact/ContactForm';
import NewForm from '../components/contact/NewForm';


function Contact() {
  console.log("Rendering Contact page...");

  return (
    <React.Fragment>
      <Helmet>
        <title>Contact</title>
        <meta name="description" content="Your meta description here" />
        <meta name="keywords" content="Your, Keywords, Here" />
        <link rel="canonical" href="https://www.ablyworks.com/" />
      </Helmet>
      <ContactHome/>
      {/* <NewForm/> */}
      <ContactForm/>
    </React.Fragment>
  )
}

export default Contact;
