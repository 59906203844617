import React from 'react';
import { Container, Row, Col, Breadcrumb } from 'react-bootstrap';
import { Link } from 'react-router-dom'

function AboutUs() {

    return (
        <>
            <section className='about-home'>
                <Container>
                    <Row>
                        <Col>
                            <div className='about'>
                                <h1>About US</h1>
                                <p>At AblyWorks, we're dedicated to transforming the world through the power of technology, fostering unity to unleash potential and<br></br> drive progress. Join us on our mission to build a brighter future, where every connection counts.</p>
                                <Link to="/contact" className='about-get'>Get in Touch</Link>
                                {/* <Breadcrumb>
                                    <Breadcrumb.Item >
                                        <Link to="/">Home</Link>
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                        About Us
                                    </Breadcrumb.Item>
                                </Breadcrumb> */}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );

}

export default AboutUs