import React, { useState, useRef } from 'react';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function ContactForm() {
    const [validated, setValidated] = useState(false);
    const [formDataResponse, setFormDataResponse] = useState({
        name: "",
        email: "",
        phone: "",
        subject: "",
        message: "",
        web: "www.ablyworks.com"
    });

    const formRef = useRef();
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { id, value } = e.target;
        if (id === "phone" && value.length > 10) {
            // Prevents input from exceeding 10 digits
            return;
        }
        setFormDataResponse((prevData) => ({
            ...prevData,
            [id]: value,
        }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
        } else {
            const formData = new FormData();
            for (const key in formDataResponse) {
                formData.append(key, formDataResponse[key]);
            }

            const apiUrl = "https://www.ablyworks.com/api/contact/savedata";

            axios.post(apiUrl, formData)
                .then((response) => {
                    if (response.status === 200) {
                        toast(response.data.message || "Success");
                        setFormDataResponse({
                            name: "",
                            email: "",
                            phone: "",
                            subject: "",
                            message: "",
                            web: "www.ablyworks.com"
                        });
                        formRef.current.reset();
                        navigate("/thank-you");
                    }
                })
                .catch((error) => {
                    console.error("Error submitting form:", error);
                });
        }
        setValidated(true);
    };

    return (
        <section className='contact-form'>
            <Container>
                <div className='shadow-sm bg-white'>
                    <Row className='g-0'>
                        <Col md={12} lg={7} xl={8} xxl={8}>
                            <Card>
                                <Card.Body>
                                    <Card.Title>Send us a Message</Card.Title>
                                    <Card.Text>If you have any questions or concerns, please do not hesitate to contact us. Our team is always available to assist you in any way we can.</Card.Text>
                                    <Form noValidate validated={validated} ref={formRef} onSubmit={handleSubmit}>
                                        <Row className='g-3'>
                                            <Form.Group as={Col} md="6" controlId="validationCustom01">
                                                <Form.Label>Full Name <span>*</span></Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    id="name"
                                                    value={formDataResponse.name}
                                                    onChange={handleChange}
                                                    required
                                                    placeholder="Name"
                                                    pattern="[A-Za-z\s]+"
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Please enter a valid name containing only alphabetic characters.
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group as={Col} md="6" controlId="validationCustom02">
                                                <Form.Label>Email <span>*</span></Form.Label>
                                                <Form.Control
                                                    type="email"
                                                    id="email"
                                                    value={formDataResponse.email}
                                                    onChange={handleChange}
                                                    required
                                                    placeholder="Email"
                                                    pattern="[a-zA-Z0-9._%+-]+@gmail\.com$"
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Please enter a valid Gmail address.
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group as={Col} md="6" controlId="validationCustom03">
                                                <Form.Label>Mobile Number <span>*</span></Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    id="phone"
                                                    value={formDataResponse.phone}
                                                    onChange={handleChange}
                                                    required
                                                    placeholder="Mobile"
                                                    pattern="^\d{10}$"
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Please enter a valid 10-digit mobile number.
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group as={Col} md="6" controlId="validationCustom04">
                                                <Form.Label>Subject <span>*</span></Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    id="subject"
                                                    value={formDataResponse.subject}
                                                    onChange={handleChange}
                                                    required
                                                    placeholder="Subject"
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Please enter a subject.
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group as={Col} lg="12" controlId="validationCustom05">
                                                <Form.Label>Message<span>*</span></Form.Label>
                                                <Form.Control
                                                 rows={3}
                                                    as="textarea"
                                                    id="message"
                                                    value={formDataResponse.message}
                                                    onChange={handleChange}
                                                    required
                                                    placeholder="Message"
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Please enter a message.
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Row>
                                        <Button type="submit">Submit</Button>
                                    </Form>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={12} lg={5} xl={4} xxl={4}>
                            <Card className='contact-us-info'>
                                <Card.Body>
                                    <h5 class="card-title">Contact Information</h5>
                                    <p class="card-text mb-0">For any queries feel free to contact us and our expert support
                                        team
                                        will get back to you as soon as possible!</p>
                                    <ul class="get-address">
                                        <li>
                                            <p>Our Corporate Office</p>
                                            <h6>3480 Preston Ridge Rd Suite 500 Alpharetta GA 30005-2028 US</h6>
                                        </li>
                                        <li>
                                            <p>Write to us</p>
                                            <h6>
                                                <a href="mailto:hello@ablyworks.com">hello@ablyworks.com</a>
                                            </h6>
                                        </li>
                                        <li>
                                            <p>Talk to us</p>
                                            <h6>
                                                <a href="tel:+1(678)772-4824">+1 (678) 772-4824</a>
                                            </h6>
                                        </li>
                                        <li class="d-flex gap-2 align-items-center">
                                            <a href="https://www.facebook.com/Weablywork" target="_blank">
                                                <i class="fa-brands fa-square-facebook"></i>
                                            </a>
                                            <a href="https://twitter.com/AblyWorks" target="_blank">
                                                <i class="fa-brands fa-square-x-twitter"></i>
                                            </a>
                                            <a href="https://www.instagram.com/ablyworks" target="_blank">
                                                <i class="fa-brands fa-square-instagram"></i>
                                            </a>
                                            <a href="https://www.linkedin.com/company/ablyworks" target="_blank">
                                                <i class="fa-brands fa-linkedin"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </Container>
        </section>
    );
}

export default ContactForm;
