import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import TermsHome from '../components/terms-conditions/TermsHome';
import TermsText from '../components/terms-conditions/TermsText';

function TermsConditions() {
  return (
    <Fragment>
        <Helmet>
        <title>Terms and Conditions - Ablyworks</title>
        <meta name="description" content="Familiarize yourself with Ablyworks' Terms and Conditions, governing the use of our software services. Understand the rules, responsibilities, and agreements." />
        <meta name="keywords" content="Your, Keywords, Here" />
        <link rel="canonical" href="https://www.ablyworks.com/" />
      </Helmet>
        <TermsHome/>
        <TermsText/>
    </Fragment>
  )
}

export default TermsConditions