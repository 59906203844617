import React from 'react';

function ConnectUs() {
  const whatsappNumber = '+9811173829';

  return (
    <section className="connect-with-us">
      <div className="container">
        <h2 data-aos="fade-up">Have a question or need assistance? Feel free to reach out to us on WhatsApp</h2>
        <div className='mt-4'>
          <a href={`https://wa.me/${whatsappNumber}`} target="_blank" rel="noopener noreferrer" data-aos="zoom-in">
            <i className="fab fa-whatsapp"></i> {whatsappNumber}
          </a>
        </div>
      </div>
    </section>
  );
}

export default ConnectUs