import React from 'react';
import ProductHome from '../components/aeps/ProductHome';
import ProductDetails from '../components/aeps/ProductDetails';
import FAQ from '../components/aeps/FAQ';
import ProductLyer from '../components/aeps/ProductLyer';
import { Helmet } from 'react-helmet';
import Features from '../components/aeps/Features';
import AepsAdharPay from '../components/aeps/AepsAdharPay';
import DomecticMT from '../components/aeps/DomecticMT';

function Ourproduct() {
    return (
        <React.Fragment>
            <Helmet>
                <title>Aeps Service, Aeps Software, Aeps Portal, Aeps Admin Panel  </title>
                <meta name="description" content="Explore top-notch AEPS service, software, portal, and admin panel solutions at AblyWorks for seamless banking transactions. Call for top notch aeps software."></meta>
                <link rel="canonical" href="https://www.ablyworks.com/" />
            </Helmet>
            <ProductHome
                title="Revolutionize Banking with Our AEPS Service Software Solutions"
                description="AblyWorks offers advanced AEPS service and software solutions, empowering customers to conduct banking transactions seamlessly using their Aadhaar number and biometric authentication. Our AEPS service facilitates a range of transactions including cash deposits, withdrawals, balance inquiries, fund transfers, and more. With our AEPS software, customers can transact conveniently at any bank branch or Business Correspondent (BC) location, leveraging the security of Aadhaar-based biometric authentication."
                buttonText="Call Now"
                buttonPhone="+919811173829"
            />
            <ProductDetails />
            <ProductLyer />
            <AepsAdharPay />
            <DomecticMT />
            <Features />
            <FAQ
            />
        </React.Fragment>
    )
}
export default Ourproduct