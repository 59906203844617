import React from 'react'
import { Container, Col, Row } from 'react-bootstrap';

function AtsAbout() {
    return (
        <>
            <section className='py-5 about-ats bg-light'>
                <Container>
                    <Row>
                        <Col xl={6}>
                            <img src="assets/images/ats-about.png" alt="" className='img-fluid rounded-1' />
                        </Col>
                        <Col xl={6}>
                            <h3>About Applicant Tracking System</h3>
                            <p className='text-secondary'>Applicant Tracking System (ATS) software revolutionizes the hiring process by providing comprehensive tools for candidate management. These systems offer features such as resume parsing, job posting, candidate screening, interview scheduling, and reporting capabilities. By automating repetitive tasks and centralizing applicant data, ATS software enhances efficiency, reduces time-to-hire, and improves the overall quality of hires. Additionally, advanced ATS platforms may incorporate artificial intelligence and machine learning algorithms to analyze resumes, predict candidate fit, and provide actionable insights for recruiters. With customizable workflows and intuitive interfaces, ATS software empowers HR teams to streamline recruitment processes, attract top talent, and make data-driven hiring decisions.</p>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className='py-5 about-ats'>
                <Container>
                    <Row>
                        <Col xl={6}>
                            <h3>ATS Recruiting Software</h3>
                            <p className='text-secondary'>ATS recruiting software simplifies hiring by centralizing job postings, resumes, and candidate communication. It automates tasks like resume parsing and screening, streamlining the recruitment process. With features for customizable workflows, interview scheduling, and reporting, ATS software enables efficient management of sourcing to onboarding.
                                ATS recruiting software is designed to streamline the hiring process by consolidating all job postings, resumes, and candidate communication in one central platform. It offers automated features such as resume parsing and screening, which help save time and effort. Additionally, the software provides customizable workflows, interview scheduling tools, and reporting capabilities, facilitating efficient management from sourcing to onboarding. With its user-friendly interface and comprehensive functionalities, ATS software is a valuable tool for modern recruitment practices.</p>
                        </Col>
                        <Col xl={6}>
                            <img src="assets/images/ats-2.jpg" alt="" className='img-fluid rounded-1' />
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default AtsAbout