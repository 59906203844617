import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';

function AtsFaq( ) {
    const accordionItems = [
        {
            header: 'What is an Applicant Tracking System (ATS)?',
            body: 'An Applicant Tracking System (ATS) is software utilized by organizations to electronically manage their recruitment process. It consolidates job postings, candidate resumes, and communication, enabling efficient candidate management from application to hire.'
        },
        {
            header: 'What is ATS and how does it work?',
            body: 'ATS, short for Applicant Tracking System, is a software tool used by employers and recruiters to streamline the hiring process. It works by allowing users to post job openings, collect and organize candidate resumes, screen applicants based on specified criteria, schedule interviews, and track candidate progress through various stages of the recruitment pipeline.'
        },
        {
            header: 'Do recruiters use ATS?',
            body: 'Yes, recruiters commonly use Applicant Tracking Systems (ATS) to manage their recruitment processes more effectively. These systems help recruiters streamline tasks such as resume screening, candidate communication, interview scheduling, and applicant tracking, ultimately improving efficiency and productivity in the hiring process.'
        },
        {
            header: '. Is ATS software free?',
            body: 'While some ATS software may offer free trials or basic versions with limited features, comprehensive ATS solutions usually involve subscription or licensing fees. Free options may lack advanced functionalities needed for complex recruitment processes.'
        },
        {
            header: 'How does AblyWorks assist in ATS software?',
            body: 'AblyWorks offers cutting-edge ATS software, streamlining candidate management with features like resume parsing and customizable workflows. Our solution simplifies the recruitment lifecycle from sourcing to onboarding, empowering recruiters to make informed hiring decisions effortlessly.'
        },
        {
            header: 'What is ATS scanning?',
            body: 'ATS scanning refers to the process through which Applicant Tracking Systems analyze and parse resumes submitted by job applicants. These systems use algorithms to scan resumes for keywords, qualifications, and relevant information, allowing recruiters to efficiently screen and filter candidates based on specific criteria.'
        }
    ];

    const [activeItem, setActiveItem] = useState(null);

    const handleAccordionItemClick = (index) => {
        setActiveItem(activeItem === index ? null : index);
    };

    return (
        <section className='product-faq'>
            <Container>
                <div className='faq-heading'>
                    <h6>FREQUENTLY ASKED QUESTIONS</h6>
                    <h2>All the answers you're looking for</h2>
                    <p>If you're new or looking for answers to your questions, this guide will help you learn more<br></br> about our services and their features.</p>
                </div>
                <Row className='gy-4'>
                    {accordionItems.map((item, index) => (
                        <Col xl={6} key={index}>
                            <Accordion activeKey={activeItem === index ? '0' : null} onSelect={() => handleAccordionItemClick(index)} flush>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>{item.header}</Accordion.Header>
                                    <Accordion.Body>{item.body}</Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col>
                    ))}
                </Row>
            </Container>
        </section>
    );
}

export default AtsFaq;
