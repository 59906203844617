import React from 'react';
import {Container, Row, Col} from 'react-bootstrap'

function HomeAbout() {
  return (
    <>
    <section className='home-about'>
        <Container>
            <Row className='align-items-center'>
                <Col xl={8} data-aos="fade-up">
                    <h6>About Us</h6>
                    <h3>Uniting People <span>Transforms the World</span></h3>
                    <p>AblyWorks has always been on a mission to reshape the world for the better, harnessing the power of technology to forge stronger bonds. These connections span across the board – from businesses to customers, from colleagues across the globe, to governments and the people they serve, and among individuals driven by change.</p>
                    <h6>Unity Equates to Strength</h6>
                    <p>The act of coming together reveals untapped potential. We align individuals to empower businesses and communities to achieve their highest aspirations, tackle their most complex obstacles, and leverage their triumphs to enhance our world.</p>

                </Col>
                <Col xl={4}>
                    <div className='about-img' data-aos="zoom-in">
                      <img src="assets/images/about.jpg" alt="" className='img-fluid'/>
                    </div>
                </Col>
                <Col xl={12} data-aos="fade-up">
                <p>As the world advances, technology progresses, and businesses adapt continuously. Yet, it's the enduring relationships, anchored in trust and respect, that prepare us for whatever the future holds.</p>
                </Col>
            </Row>
        </Container>
    </section>
    </>
  )
}

export default HomeAbout