import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';

function PrivacyText() {
  const privacyPolicySections = [
    {
      title: "What Personally Identifiable Information is collected?",
      content: "We may collect basic user profile information from all of our Visitors. We collect the following additional information from our Authorized Customers: the names, addresses, phone numbers and email addresses of Authorized Customers, the nature and size of the business, and the nature and size of the advertising inventory that the Authorized Customer intends to purchase or sell."
    },
    {
      title: "What organizations are collecting the information?",
      content: "In addition to our direct collection of information, our third party service vendors (such as credit card companies, clearinghouses and banks) who may provide such services as credit, insurance, and escrow services may collect this information from our Visitors and Authorized Customers."
    },
    {
      title: "How does the Site use Personally Identifiable Information?",
      content: "We use Personally Identifiable Information to customize the Site, to make appropriate service offerings, and to fulfill buying and selling requests on the Site. We may email Visitors and Authorized Customers about research or purchase and selling opportunities on the Site or information related to the subject matter of the Site."
    },
    {
      title: "With whom may the information may be shared?",
      content: "Personally Identifiable Information about Authorized Customers may be shared with other Authorized Customers who wish to evaluate potential transactions with other Authorized Customers. We may share aggregated information about our Visitors, including the demographics of our Visitors and Authorized Customers, with our affiliated agencies and third party vendors."
    },
    {
      title: "How is Personally Identifiable Information stored?",
      content: "Personally Identifiable Information collected by ablyworks is securely stored and is not accessible to third parties or employees of ablyworks except for use as indicated above."
    },
    {
      title: "What choices are available to Visitors regarding collection, use and distribution of the information?",
      content: "Visitors and Authorized Customers may opt out of receiving unsolicited information from or being contacted by us and/or our vendors and affiliated agencies by responding to emails as instructed, or by contacting us at 3480 Preston Ridge RdSuite 500 Alpharetta GA 30005-2028 US."
    },
    {
      title: "Are Cookies Used on the Site?",
      content: "Cookies are used for a variety of reasons. We use Cookies to obtain information about the preferences of our Visitors and the services they select. We also use Cookies for security purposes to protect our Authorized Customers."
    },
    {
      title: "How does ablyworks use login information?",
      content: "Ablyworks uses login information, including, but not limited to, IP addresses, ISPs, and browser types, to analyze trends, administer the Site, track a user’s movement and use, and gather broad demographic information."
    },
    {
      title: "What partners or service providers have access to Personally Identifiable Information from Visitors and/or Authorized Customers on the Site?",
      content: "Ablyworks has entered into and will continue to enter into partnerships and other affiliations with a number of vendors. Such vendors may have access to certain Personally Identifiable Information on a need to know basis for evaluating Authorized Customers for service eligibility."
    },
    {
      title: "Disclosure of Personally Identifiable Information to comply with law.",
      content: "We will disclose Personally Identifiable Information in order to comply with a court order or subpoena or a request from a law enforcement agency to release information. We will also disclose Personally Identifiable Information when reasonably necessary to protect the safety of our Visitors and Authorized Customers."
    },
    {
      title: "How does the Site keep Personally Identifiable Information secure?",
      content: "All of our employees are familiar with our security policy and practices. The Personally Identifiable Information of our Visitors and Authorized Customers is only accessible to a limited number of qualified employees who are given a password in order to gain access to the information."
    },
    {
      title: "How can Visitors correct any inaccuracies in Personally Identifiable Information?",
      content: "Visitors and Authorized Customers may contact us to update Personally Identifiable Information about them or to correct any inaccuracies by emailing us at info@ablyworks.com."
    },
    {
      title: "Can a Visitor delete or deactivate Personally Identifiable Information collected by the Site?",
      content: "We provide Visitors and Authorized Customers with a mechanism to delete/deactivate Personally Identifiable Information from the Site’s database by contacting us. However, because of backups and records of deletions, it may be impossible to delete a Visitor’s entry without retaining some residual information."
    },
    {
      title: "What happens if the Privacy Policy Changes?",
      content: "We will let our Visitors and Authorized Customers know about changes to our privacy policy by posting such changes on the Site. However, if we are changing our privacy policy in a manner that might cause disclosure of Personally Identifiable Information that a Visitor or Authorized Customer has previously requested not be disclosed, we will contact such Visitor or Authorized Customer to allow such Visitor or Authorized Customer to prevent such disclosure."
    },
    {
      title: "Links:",
      content: "www.ablyworks.com contains links to other web sites. Please note that when you click on one of these links, you are moving to another web site. We encourage you to read the privacy statements of these linked sites as their privacy policies may differ from ours."
    }
  ];

  return (
    <section className='privacy-text'>
      <Container>
        <Row className='justify-content-center'>
          <Col xl={8}>
            <Card>
              <Card.Body>
                {privacyPolicySections.map((section, index) => (
                  <ul key={index} data-aos="fade-up">
                    <li className='card-title'>{section.title}</li>
                    <p>{section.content}</p>
                  </ul>
                ))}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default PrivacyText;
