import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';

function WhyChoose() {
  // Data for each column
  const features = [
    {
      id: 1,
      imageSrc: "assets/images/workflow.png",
      title: "Customizable Workflow",
      description: "Set the recruitment process to fit according to your company's unique needs, with customizable stages, actions and notifications to streamline candidate management."
    },
    {
      id: 2,
      imageSrc: "assets/images/job-search.png",
      title: "Advanced Candidate Search",
      description: "Efficiently find the best candidates with advanced search filters, allowing you to search based on skills, experience, education, location, and more, helping you quickly identify relevant talent."
    },
    {
      id: 3,
      imageSrc: "assets/images/cv.png",
      title: "Collaborative Hiring Tools",
      description: "Enable seamless collaboration among hiring teams with features like shared candidate feedback, interview scheduling, centralized communication, facilitating efficient decision-making and reducing time-to-hire."
    },
  ];

  return (
    <>
      <section className='why-choose-us'>
        <Container>
          <div className="web-heading" data-aos="fade-up">
            <h2>What Makes Us <span>Different</span></h2>
            <p>Revolutionize your recruitment process with customizable workflows, advanced candidate search, <br></br>and seamless collaborative hiring tools.</p>
          </div>
          <Row className="row-cols-xxl-3 row-cols-xl-3 row-cols-lg-3 row-cols-md-2 row-cols-1 g-xl-5 g-lg-4 g-md-4 g-3">
            {features.map(feature => (
              <Col key={feature.id} className='d-flex'>
                <Card data-aos="fade-up">
                  <Card.Body>
                    <ul>
                      <li>
                        <img src={feature.imageSrc} alt={feature.title} />
                      </li>
                      <li><h5>{feature.title}</h5></li>
                    </ul>
                    <p>{feature.description}</p>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    </>
  )
}

export default WhyChoose;
