import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap'

function OurValues() {

  return (
    <>
      <section className='our-values'>
        <Container>
          <h3>Living Our Values</h3>
          <p>We're convinced that business is a powerful catalyst for progress. We advocate for stakeholder capitalism, ensuring our endeavours benefit all our stakeholders – customers, staff, partners, communities, the planet, and society at large.</p>
          <p>We take the lead, initiating projects in philanthropy, racial justice and equality, climate advocacy, and the ethical application of technology.</p>
          <Row className='row-cols-5'>
            <Col>
              <Card>
                <Card.Body>
                    <img src="assets/images/Stakeholder.png" alt="Stakeholder.png" />
                    <h6 className="card-title">Stakeholder Capitalism</h6>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card>
                <Card.Body>
                    <img src="assets/images/Business.png" alt="Stakeholder.png" />
                    <h6 className="card-title">Business<br></br> leadership</h6>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card>
                <Card.Body>
                    <img src="assets/images/Ethical.png" alt="Ethical" />
                    <h6 className="card-title">Ethical<br></br> Technology</h6>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card>
                <Card.Body>
                    <img src="assets/images/Community.png" alt="Community.png" />
                    <h6 className="card-title">Community<br></br> Engagement</h6>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card>
                <Card.Body>
                    <img src="assets/images/Climate.png" alt="Climate.png" />
                    <h6 className="card-title">Climate<br></br> Advocacy</h6>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
export default OurValues