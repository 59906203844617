import React from 'react';
import ProductHome from '../aeps/ProductHome';

const AtsHome = () => {
    const atsContent = {
        title: "Applicant Tracking System (ATS) Software - Recruiting Software",
        description: " Explore top Applicant Tracking System (ATS) software for efficient hiring. Streamline candidate management & optimize recruitment with the best ATS solutions",
        buttonText: "Call Now",
        buttonPhone: "+919812345670",
    };

    console.log("Rendering AtsHome with props:", atsContent);

    return (
        <ProductHome {...atsContent} />
    );
};

export default AtsHome;
