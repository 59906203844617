import React, { useState } from 'react';
import { Container, Row, Col, Card, Carousel } from 'react-bootstrap';

function TrustedClient() {
  const [additionalImages, setAdditionalImages] = useState([]);

  const clientLogos = [
    "assets/images/company/paymonk.png",
    "assets/images/company/namrah.png",
    "assets/images/company/payveda.png",
    "assets/images/company/markpay.png",
    "assets/images/company/herizen.png"
  ];

  const handleAddImage = () => {
    // Function to add a new image to the carousel
    const newImage = "path/to/new/image.png";
    setAdditionalImages([...additionalImages, newImage]);
  };

  return (
    <section className='trusted-client'>
      <Container>
        <div className='web-heading' data-aos="fade-up">
          <h2>Trusted By Over <span>800+ Clients</span> Worldwide</h2>
          <p>Experience unparalleled trust and reliability with our services, endorsed by over 800 satisfied clients spanning the globe.</p>
        </div>
        <Row>
          <Col>
            <Card data-aos="fade-up">
              <Card.Body>
                <Carousel>
                  <Carousel.Item>
                    <ul className='list-unstyled d-flex justify-content-around m-0 p-0'>
                      {clientLogos.map((logo, index) => (
                        <li key={index} className='text-center'>
                          <img src={logo} alt={`Client Logo ${index + 1}`} className="img-fluid" />
                        </li>
                      ))}
                    </ul>
                  </Carousel.Item>
                  {additionalImages.map((image, index) => (
                    <Carousel.Item key={`additional-${index}`}>
                      <ul className='list-unstyled d-flex justify-content-around'>
                        <li className='text-center'>
                          <img src={image} alt={`Additional Image ${index + 1}`} className="img-fluid" />
                        </li>
                      </ul>
                    </Carousel.Item>
                  ))}
                </Carousel>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default TrustedClient;
