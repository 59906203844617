import React from 'react-bootstrap';
import { Container, Row, Col } from 'react-bootstrap'

function OurCulture() {
    return (

        <section className='our-culture'>
            <Container>
                <h3>Our <span>Culture</span></h3>
                <h6>We Are One Big Family</h6>
                <Row>
                    <Col xl={6}>
                        <p>
                            We are not your average company, and we pride ourselves on that distinction. We refer to collective work of AblyWorks staff, clients, and partners as "Trailblazers." We are a unique family of trailblazers that transcends roles, companies, and sectors, aiding one another in learning, growth, and success.</p>
                        <p>
                            Our commitment to customer success and societal betterment is serious business, yet we take great joy in our collective journey.</p>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default OurCulture