import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Navbar, Nav, Button, NavDropdown } from 'react-bootstrap';

function AppNavbar() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [isVisible, setIsVisible] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  const handleScroll = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  window.addEventListener("scroll", handleScroll);


  return (
    <>
      <Navbar expand="lg" className='appnavbar'>
        <Container>
          <Navbar.Brand as={Link} to="/"><img src="assets/images/logo.png" alt="Logo" /></Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav className="ms-auto" navbarScroll>
              <Nav.Link as={Link} to="/">Home</Nav.Link>
              <Nav.Link as={Link} to="/about">About</Nav.Link>
              <NavDropdown title="Product" id="navbarScrollingDropdown">
                <NavDropdown.Item as={Link} to="/aeps">
                  AEPS
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/hrms">
                  HRMS
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/ats">
                  ATS
                </NavDropdown.Item>
              </NavDropdown>
              <Button as={Link} to="/contact">Contact
              </Button>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Link className="back-to-top-btn" style={{ display: isVisible ? 'block' : 'none', }} onClick={scrollToTop}><i class="fa-solid fa-arrow-up"></i></Link>
    </>
  )
}
export default AppNavbar