import React from 'react';
import {Container, Row, Col} from 'react-bootstrap'


function EachSolution(){
    return(
        <>
         <section className='eachsolution'>
            <Container>
                <Row>
                    <Col xl={9} className='mx-auto'>
                       <h4>Each solution we provide plays a role in cultivating the connections that underpin success – success that can be directed towards positive change</h4>
                    </Col>
                </Row>
            </Container>
         </section>
        </>
    )
}

export default EachSolution