import React, { useState, useRef } from 'react';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

function HrmsDetails() {

  const [validated, setValidated] = useState(false);
  const [formDataResponse, setFormDataResponse] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
    web: "www.ablyworks.com"
  });

  const formRef = useRef();
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { id, value } = e.target;
    if (id === "phone" && value.length > 10) {
      // Prevents input from exceeding 10 digits
      return;
    }
    setFormDataResponse((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      const formData = new FormData();
      for (const key in formDataResponse) {
        formData.append(key, formDataResponse[key]);
      }

      const apiUrl = "https://www.ablyworks.com/api/contact/savedata";

      axios.post(apiUrl, formData)
        .then((response) => {
          if (response.status === 200) {
            toast(response.data.message || "Success");
            setFormDataResponse({
              name: "",
              email: "",
              phone: "",
              subject: "",
              message: "",
              web: "www.ablyworks.com"
            });
            formRef.current.reset();
            navigate("/thank-you");
          }
        })
        .catch((error) => {
          console.error("Error submitting form:", error);
        });
    }
    setValidated(true);
  };

  return (
    <>
      <section className='product-details'>
        <Container>
          <Row className='gx-5 align-items-center'>
            <Col xl={8}>
              <h2>Streamlining <span>HR and Payroll</span> Processes with Ablyworks</h2>
              <h4>Empowering Social Welfare Educational Institutions for Meritorious Students</h4>
              <p>Integrate biometric attendance systems with Ablyworks payroll easily. Payroll administration made easy with Ablyworks HRMS. 650+ Enterprises. World Class Security. End-to-End ELC Management. Fastest Growing HCM. #1 Rated HCM By Gartner. Core Configurability.</p>
              <p>To establish, maintain control and manage Social Welfare Residential Schools and Colleges for the talented and meritorious children belonging to Scheduled Cases ...</p>
            </Col>
            <Col xl={4}>
              <Card className='shadow-sm border-2 border'>
                <Card.Body>
                  <h4>Enquiry Now!</h4>
                  <Form noValidate validated={validated} ref={formRef} onSubmit={handleSubmit}>
                    <Row className='g-3'>
                      <Form.Group as={Col} md="12" controlId="validationCustom01">
                        <Form.Control
                          type="text"
                          id="name"
                          value={formDataResponse.name}
                          onChange={handleChange}
                          required
                          placeholder="Name"
                          pattern="[A-Za-z\s]+"
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter a valid name containing only alphabetic characters.
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group as={Col} md="12" controlId="validationCustom02">
                        <Form.Control
                          type="email"
                          id="email"
                          value={formDataResponse.email}
                          onChange={handleChange}
                          required
                          placeholder="Email"
                          pattern="[a-zA-Z0-9._%+-]+@gmail\.com$"
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter a valid Gmail address.
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group as={Col} md="12" controlId="validationCustom03">
                        <Form.Control
                          type="text"
                          id="phone"
                          value={formDataResponse.phone}
                          onChange={handleChange}
                          required
                          placeholder="Mobile"
                          pattern="^\d{10}$"
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter a valid 10-digit mobile number.
                        </Form.Control.Feedback>
                      </Form.Group>
                      {/* <Form.Group as={Col} md="12" controlId="validationCustom04">
                        <Form.Control
                          type="text"
                          id="subject"
                          value={formDataResponse.subject}
                          onChange={handleChange}
                          required
                          placeholder="Subject"
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter a subject.
                        </Form.Control.Feedback>
                      </Form.Group> */}
                      <Form.Group as={Col} lg="12" controlId="validationCustom05">
                        <Form.Control
                          id="message"
                          value={formDataResponse.message}
                          onChange={handleChange}
                          required
                          placeholder="Message"
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter a message.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Row>
                    <Button type="submit" className='my-3 w-100'>Submit</Button>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default HrmsDetails