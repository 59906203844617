import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import {Link} from 'react-router-dom'

function Aeps() {
  return (
    <>
     <section className='aeps-services'>
        <Container>
            <Row className='row-cols-xl-2 row-cols-lg-2 row-cols-md-2 row-cols-1 g-4'>
                <Col className='order-lg-1 order-2'>
                    <img src="assets/images/b2b.jpg" alt="final.png" className='img-fluid shadow-sm'/>
                </Col>
                <Col className='order-lg-2'>
                  <div className='web-heading text-start' data-aos="fade-up">
                    <h2>Introducing Our Top-Notch B2B AEPS Software</h2>
                    <p>Begin your journey with Ablyworks' Cutting-Edge Aadhar-enabled payment system (AEPS) software, and revolutionize your financial transactions effortlessly! Our AEPS B2B portal caters to a diverse clientele seeking seamless money transfers, cash withdrawals, and quick access to mini statements, all with minimal effort. Whether you're venturing into entrepreneurship with limited investment or seeking lucrative opportunities as an admin, our advanced AEPS software portal is the perfect solution to kickstart your mini-banking business and earn substantial commissions.</p>
                  </div>
                  <Button as={Link} to="/aeps" data-aos="zoom-in">Read More</Button>
                </Col>
            </Row>
        </Container>
     </section>
    </>
  )
}

export default Aeps