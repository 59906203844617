import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

function ProductLyer() {
  return (
    <>
      <section className='product-layer'>
        <Container>
          <Row className='row-cols-xl-2 row-cols-1'>
            <Col>
               <img src="assets/images/layer.jpg" alt="layer.png" height={400} width={500} className='rounded-3 img-fluid' />
            </Col>
            <Col>
              <h2>Software <span>With 6 Layers</span></h2>
              <p>The AEPS (Aadhaar Enabled Payment System) is a secure payment system utilizing Aadhaar biometric authentication for financial transactions. Expand your White Labels by enabling them to create their own sub-White Labels. Although there isn't a standardized 6-layer architecture for AEPS software, a potential example could be as outlined below.</p>
              <li><span><img src="assets/images/arrow.png" alt="arrow.png"/></span>Admin</li>
              <li><span><img src="assets/images/arrow.png" alt="arrow.png"/></span>Master White Labels</li>
              <li><span><img src="assets/images/arrow.png" alt="arrow.png"/></span>White Labels</li>
              <li><span><img src="assets/images/arrow.png" alt="arrow.png"/></span>Super Distributor</li>
              <li><span><img src="assets/images/arrow.png" alt="arrow.png"/></span>Distributor</li>
              <li><span><img src="assets/images/arrow.png" alt="arrow.png"/></span>Agent</li>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}
export default ProductLyer