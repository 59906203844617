import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function Hrms() {
  return (
    <>
      <section className='hrms-info'>
        <Container>
          <Row className='align-items-center row-cols-1 row-cols-lg-2 row-cols-md-1'>
            <Col>
              <div className='web-heading' data-aos="fade-up">
                <h2>Seamless HRMS & Payroll Solutions</h2>
                <p>Discover the versatility of our HR and payroll solution, tailored for businesses of every size, from start-ups to large enterprises. With an intuitive Admin Panel, effortlessly manage multiple departments, employees, projects, and more. No matter your company's scale, our solution efficiently handles Max your HR-related tasks with ease.</p>
              </div>
              <li><span><img src="assets/images/arrow.png" alt="arrow.png" /></span>Emoloyees</li>
              <li><span><img src="assets/images/arrow.png" alt="arrow.png" /></span>Department</li>
              <li><span><img src="assets/images/arrow.png" alt="arrow.png" /></span>Awards</li>
              <li><span><img src="assets/images/arrow.png" alt="arrow.png" /></span>Expense</li>
              <li><span><img src="assets/images/arrow.png" alt="arrow.png" /></span>Holiday</li>
              <li><span><img src="assets/images/arrow.png" alt="arrow.png" /></span>Attendance</li>
              <li><span><img src="assets/images/arrow.png" alt="arrow.png" /></span>And Many More</li>
              <Button as={Link} to="/hrms">Read More</Button>
            </Col>
            <Col>
              <div className='hrms-img shadow-sm'>
                 <img src="assets/images/crm-dashboard.png" alt="hrms.png" className='img-fluid' data-aos="zoom-in"/>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default Hrms