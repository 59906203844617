import React, { useState, useRef } from 'react';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

function ProductDetails() {

  const [validated, setValidated] = useState(false);
  const [formDataResponse, setFormDataResponse] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
    web: "www.ablyworks.com"
  });

  const formRef = useRef();
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { id, value } = e.target;
    if (id === "phone" && value.length > 10) {
      // Prevents input from exceeding 10 digits
      return;
    }
    setFormDataResponse((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      const formData = new FormData();
      for (const key in formDataResponse) {
        formData.append(key, formDataResponse[key]);
      }

      const apiUrl = "https://www.ablyworks.com/api/contact/savedata";

      axios.post(apiUrl, formData)
        .then((response) => {
          if (response.status === 200) {
            toast(response.data.message || "Success");
            setFormDataResponse({
              name: "",
              email: "",
              phone: "",
              subject: "",
              message: "",
              web: "www.ablyworks.com"
            });
            formRef.current.reset();
            navigate("/thank-you");
          }
        })
        .catch((error) => {
          console.error("Error submitting form:", error);
        });
    }
    setValidated(true);
  };

  return (
    <>
      <section className='product-details'>
        <Container>
          <Row className='gx-5 align-items-center'>
            <Col xl={8}>
              <h2>Start Your Own <span>Business With Our B2B Admin</span> Portal</h2>
              <h4>Robust & Tested B2B Admin Software Platform</h4>
              <p>Why settle for a White Label when you can customize your own AEPS Service? Our AEPS Software provides comprehensive functionalities, granting you full control over your retail network. With our AEPS Portal, your retailers remain exclusively under your purview, ensuring unparalleled visibility. Additionally, leverage our B2B Admin Platform to seamlessly onboard CSPs. Leave the software and hosting management to us while you focus on growing your business. AEPS: Secure, swift, and reliable, championing financial inclusion as part of India's Digital India initiative.</p>
              <h4>We are offering two options</h4>
              <li>Complete AEPS Software </li>
              <li>Admin Panel</li>
            </Col>
            <Col xl={4}>
              <Card className='shadow-sm border-2 border'>
                <Card.Body>
                  <h4>Enquiry Now!</h4>
                  <Form noValidate validated={validated} ref={formRef} onSubmit={handleSubmit}>
                    <Row className='g-3'>
                      <Form.Group as={Col} md="12" controlId="validationCustom01">
                        <Form.Control
                          type="text"
                          id="name"
                          value={formDataResponse.name}
                          onChange={handleChange}
                          required
                          placeholder="Name"
                          pattern="[A-Za-z\s]+"
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter a valid name containing only alphabetic characters.
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group as={Col} md="12" controlId="validationCustom02">
                        <Form.Control
                          type="email"
                          id="email"
                          value={formDataResponse.email}
                          onChange={handleChange}
                          required
                          placeholder="Email"
                          pattern="[a-zA-Z0-9._%+-]+@gmail\.com$"
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter a valid Gmail address.
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group as={Col} md="12" controlId="validationCustom03">
                        <Form.Control
                          type="text"
                          id="phone"
                          value={formDataResponse.phone}
                          onChange={handleChange}
                          required
                          placeholder="Mobile"
                          pattern="^\d{10}$"
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter a valid 10-digit mobile number.
                        </Form.Control.Feedback>
                      </Form.Group>
                      {/* <Form.Group as={Col} md="12" controlId="validationCustom04">
                        <Form.Control
                          type="text"
                          id="subject"
                          value={formDataResponse.subject}
                          onChange={handleChange}
                          required
                          placeholder="Subject"
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter a subject.
                        </Form.Control.Feedback>
                      </Form.Group> */}
                      <Form.Group as={Col} lg="12" controlId="validationCustom05">
                        <Form.Control
                          id="message"
                          value={formDataResponse.message}
                          onChange={handleChange}
                          required
                          placeholder="Message"
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter a message.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Row>
                    <Button type="submit" className='my-3 w-100'>Submit</Button>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default ProductDetails