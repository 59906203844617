import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import {Link} from 'react-router-dom'

function Ats() {
  return (
    <>
     <section className='ats-about'>
        <Container>
            <Row className='align-items-center'>
                <Col xl={6}>
                  <div className='web-heading text-start' data-aos="fade-up">
                      <h2>Track Applications</h2>
                      <p>Optimize your recruitment process with our state-of-the-art Applicant Tracking System (ATS) software. Crafted to streamline and improve every aspect of your talent acquisition journey, our innovative recruiting software eliminates manual tasks and enhances efficiency. Bid farewell to inefficiencies – our ATS applicant tracking system provides seamless integration, user-friendly features, and unmatched effectiveness. Enhance your hiring endeavors today with our advanced ATS software!</p>
                  </div>
                    <Button as={Link} to="/ats" data-aos="zoom-in">Read More</Button>
                </Col>
                <Col xl={6}>
                     <img src="assets/images/ats-dashboard.png" alt="ats-dashboard.png" className='img-fluid'/>
                </Col>
            </Row>
        </Container>
     </section>
    </>
  )
}

export default Ats