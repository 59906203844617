import React from 'react';
import { Container, Row, Col,  } from 'react-bootstrap';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import {Link} from 'react-router-dom'


function PrivacyHome() {
    return (
        <>
            <section className='privacy-policy'>
                <Container>
                    <Breadcrumb className='app-breadcrumb' data-aos="fade-up">
                        <Breadcrumb.Item>
                          <Link to="/">Home</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>Privacy-Policy</Breadcrumb.Item>
                    </Breadcrumb>
                    <Row>
                        <Col>
                          <div className='web-heading' data-aos="fade-up">
                              <h2>Privacy-Policy</h2>
                          </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default PrivacyHome
