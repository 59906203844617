import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

function AtsFeatures() {
    const atsFeatures = [
        { id: 1, title: 'Social Media Integration', iconSrc: 'assets/images/feature-icons/icon-47.png' },
        { id: 2, title: 'Export to excel', iconSrc: 'assets/images/feature-icons/icon-48.png' },
        { id: 3, title: 'Quick Filters', iconSrc: 'assets/images/feature-icons/icon-49.png' },
        { id: 4, title: 'Applicant Rating', iconSrc: 'assets/images/feature-icons/icon-50.png' },
        { id: 5, title: 'Questionnaires', iconSrc: 'assets/images/feature-icons/icon-51.png' },
        { id: 6, title: 'Flexible hiring roles', iconSrc: 'assets/images/feature-icons/icon-52.png' },
        { id: 7, title: 'Multiple Languages', iconSrc: 'assets/images/feature-icons/icon-54.png' },
        // ...
    ];

    return (
        <>
            <section className='features'>
                <Container>
                    <h2>Our <span>Features</span></h2>
                    <Row className='row-cols-xl-6 g-3 row-cols-2 justify-content-center'>
                        {atsFeatures.map(feature => (
                            <Col key={feature.id}>
                                <div className="feature-icon">
                                    <div className='icon'>
                                        <img src={feature.iconSrc} alt="feature-icon"/>
                                    </div>
                                    <div className='card-content'>
                                        <h4>{feature.title}</h4>
                                    </div>
                                </div>
                            </Col>
                        ))}
                    </Row>
                </Container>
            </section>
        </>
    );
}

export default AtsFeatures;
