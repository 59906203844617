import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';

function Features(){
    const features = [
        { id: 1, title: 'Bank Grade security', iconSrc: 'assets/images/feature-icons/bank.png' },
        { id: 2, title: 'Patching and server maintenance', iconSrc: 'assets/images/feature-icons/icon-3.png ' },
        { id: 3, title: 'Event Logs', iconSrc: 'assets/images/feature-icons/icon-4.png' },
        { id: 4, title: 'System Integrity Controls', iconSrc: 'assets/images/feature-icons/icon-1.png' },
        { id: 5, title: 'Antivirus and Anti Malware', iconSrc: 'assets/images/feature-icons/icon-5.png' },
        { id: 6, title: 'Authentication & access controls', iconSrc: 'assets/images/feature-icons/icon-6.png' },
        { id: 7, title: 'RBI Compliant Server', iconSrc: 'assets/images/feature-icons/icon-7.png' },
        { id: 8, title: 'Recharges', iconSrc: 'assets/images/feature-icons/icon-8.png' },
        { id: 9, title: 'Bill Payments', iconSrc: 'assets/images/feature-icons/icon-9.png' },
        { id: 10, title: 'CMS', iconSrc: 'assets/images/feature-icons/icon-10.png' },
        { id: 11, title: 'Insurance', iconSrc: 'assets/images/feature-icons/icon-11.png' },
        { id: 12, title: 'LIC Payments', iconSrc: 'assets/images/feature-icons/icon-12.png' },
        { id: 13, title: 'Credit Card Payments', iconSrc: 'assets/images/feature-icons/icon-13.png' },
        { id: 14, title: 'IRCTC', iconSrc: 'assets/images/feature-icons/icon-14.png' },
        { id: 15, title: 'Travel Tickets', iconSrc: 'assets/images/feature-icons/icon-15.png' },
        { id: 16, title: 'LPG Gas Booking', iconSrc: 'assets/images/feature-icons/icon-16.png' },
        { id: 17, title: 'Lending & Loans', iconSrc: 'assets/images/feature-icons/icon-17.png' },
        { id: 18, title: 'Bank Account Opening', iconSrc: 'assets/images/feature-icons/icon-18.png' },
        { id: 19, title: 'Municipality Payments', iconSrc: 'assets/images/feature-icons/icon-19.png' },
        { id: 20, title: 'Tele-Medicine', iconSrc: 'assets/images/feature-icons/icon-20.png' },
        { id: 21, title: 'FasTAG', iconSrc: 'assets/images/feature-icons/icon-21.png' },
        { id: 22, title: 'Unlimited White Labels', iconSrc: 'assets/images/feature-icons/icon-22.png' },
        { id: 23, title: 'Auto Wallet TopUp', iconSrc: 'assets/images/feature-icons/icon-23.png' },
        { id: 24, title: 'Auto Settlement', iconSrc: 'assets/images/feature-icons/icon-24.png' },
        { id: 25, title: 'Readtime Dashboard', iconSrc: 'assets/images/feature-icons/icon-25.png' },
        { id: 26, title: 'View daily Commission', iconSrc: 'assets/images/feature-icons/icon-26.png' },
        { id: 27, title: 'Business Ledger', iconSrc: 'assets/images/feature-icons/icon-27.png' },
        { id: 28, title: 'Android App', iconSrc: 'assets/images/feature-icons/icon-28.png' },
    ];

    return (
        <>
            <section className='features'>
                <Container>
                    <h2>Our <span>Features</span></h2>
                    <Row className='row-cols-xl-6 g-3 row-cols-2'>
                        {features.map(feature => (
                            <Col key={feature.id}>
                                <div className="feature-icon">
                                    <div className='icon'>
                                        <img src={feature.iconSrc} alt="feature-icon"/>
                                    </div>
                                    <div className='card-content'>
                                        <h4>{feature.title}</h4>
                                    </div>
                                </div>
                            </Col>
                        ))}
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default Features;
