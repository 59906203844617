import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';

function FAQ( ) {
    const accordionItems = [
        {
            header: 'What is AEPS service?',
            body: 'AEPS (Aadhaar Enabled Payment System) service is a secure payment method that allows individuals to perform banking transactions using their Aadhaar number and biometric authentication, such as fingerprints or iris scans.'
        },
        {
            header: 'How do I activate AEPS service?',
            body: 'To activate AEPS service, you must register with a bank or financial institution offering AEPS facilities. Upon registration, you will receive the necessary credentials and access to the required software or portal for initiating AEPS transactions.'
        },
        {
            header: 'What is AEPS Software?',
            body: 'AEPS software is a digital platform or application designed to facilitate AEPS transactions. It enables users to conduct various banking activities, including cash withdrawal, balance inquiry, and fund transfer, using Aadhaar-based biometric authentication. '
        },
        {
            header: 'Which is the Best Indian Company for AEPS Software?',
            body: 'AblyWorks stands out as one of the premier Indian companies specializing in AEPS software solutions. Renowned for its commitment to innovation, reliability, and customer satisfaction, AblyWorks offers superior AEPS software tailored to cater to the diverse requirements of businesses and individuals alike.'
        },
        {
            header: 'What will I get in B2B Admin Software ?',
            body: 'In B2B Admin Software, we will provide you with the software and you will be able to host it on our server or your own server. You will be able to add your own APIs, whichever you want. You can have your own Tech Team to maintain and manage your software. However, optionally, if you want, we can maintain your software as well. We can become your outsourced Tech Team.'
        }
    ];

    const [activeItem, setActiveItem] = useState(null);

    const handleAccordionItemClick = (index) => {
        setActiveItem(activeItem === index ? null : index);
    };

    return (
        <section className='product-faq'>
            <Container>
                <div className='faq-heading'>
                    <h6>FREQUENTLY ASKED QUESTIONS</h6>
                    <h2>All the answers you're looking for</h2>
                    <p>If you're new or looking for answers to your questions, this guide will help you learn more<br></br> about our services and their features.</p>
                </div>
                <Row className='gy-4'>
                    {accordionItems.map((item, index) => (
                        <Col xl={6} key={index}>
                            <Accordion activeKey={activeItem === index ? '0' : null} onSelect={() => handleAccordionItemClick(index)} flush>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>{item.header}</Accordion.Header>
                                    <Accordion.Body>{item.body}</Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col>
                    ))}
                </Row>
            </Container>
        </section>
    );
}

export default FAQ;
