import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';

function HrmsFaq( ) {
    const accordionItems = [
        {
            header: 'Which is the best software for HR?',
            body: 'The best software for HR depends on the specific needs and requirements of your organization. AblyWorks offers advanced HR software solutions tailored to streamline various HR processes efficiently.'
        },
        {
            header: 'What is the HR portal?',
            body: 'An HR portal is a centralized platform that allows employees and HR professionals to access HR-related information, policies, documents, and tools. It serves as a hub for all HR-related activities, including employee self-service, training, benefits enrollment, and more.',
        },
        {
            header: 'What are the benefits of HR portals?',
            body: 'numerous benefits, including improved accessibility to HR information and services, enhanced employee engagement and satisfaction, streamlined HR processes, reduced administrative burden on HR staff, and increased efficiency in managing HR-related tasks.',
        },
        {
            header: 'How does AblyWorks help us in HR Software?',
            body: 'AblyWorks provides comprehensive HR software solutions designed to streamline HR processes, improve efficiency, and enhance employee management. With our innovative HR software, organizations can automate routine tasks, centralize employee data, streamline recruitment, manage performance, and much more, ultimately leading to improved HR operations and organizational success.',
        },
      
        
    ];

    const [activeItem, setActiveItem] = useState(null);

    const handleAccordionItemClick = (index) => {
        setActiveItem(activeItem === index ? null : index);
    };

    return (
        <section className='product-faq'>
            <Container>
                <div className='faq-heading'>
                    <h6>FREQUENTLY ASKED QUESTIONS</h6>
                    <h2>All the answers you're looking for</h2>
                    <p>If you're new or looking for answers to your questions, this guide will help you learn more<br></br> about our services and their features.</p>
                </div>
                <Row className='gy-4'>
                    {accordionItems.map((item, index) => (
                        <Col xl={6} key={index}>
                            <Accordion activeKey={activeItem === index ? '0' : null} onSelect={() => handleAccordionItemClick(index)} flush>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>{item.header}</Accordion.Header>
                                    <Accordion.Body>{item.body}</Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col>
                    ))}
                </Row>
            </Container>
        </section>
    );
}

export default HrmsFaq;
