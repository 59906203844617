import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import {Link} from 'react-router-dom'

function ThankYou() {
    return (
        <>
            <section className='thank-you py-5'>
                <Container>
                    <Row>
                        <Col xl={6} className="mx-auto">
                            <Card>
                                <Card.Body>
                                    <img src="assets/images/thank-you.png" alt="" />
                                    <p>We are delighted to see your response. Our team is excited to work with you! We will get back to you soon.</p>
                                    <Link to="/" className='px-5 btn btn-primary'>Go to home page</Link>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default ThankYou
