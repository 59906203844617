import React from 'react';
import HomeAbout from '../components/home/HomeAbout';
import {Helmet} from 'react-helmet';
import AboutUs from '../components/about/AboutUs';
import OurValues from '../components/about/OurValues';
import OurMission from '../components/about/OurMission';
import EachSolution from '../components/about/EachSolution';
import OurCulture from '../components/about/OurCulture';
import NeedGuidance from '../components/about/NeedGuidance';

function About() {
  return (
    <React.Fragment>
      <Helmet>
      <title>About Us - Ablyworks</title>
      <meta name="description" content="Discover the story behind Ablyworks, a leading software company providing innovative HR, ATS, and other business solutions."></meta>
      <link rel="canonical" href="https://www.ablyworks.com/" />
      </Helmet>
      <AboutUs />
      <HomeAbout />
      <OurMission/>
      <EachSolution/>
      <OurValues/>
      <OurCulture/>
      <NeedGuidance/> 
    </React.Fragment>
  )
}

export default About