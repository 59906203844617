import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom'

function Footer() {
    return (
        <div>
            <footer>
                <Container>
                    <Row className='row-cols-xxl-4 row-cols-xl-4 row-cols-lg-4 row-cols row-cols-md-3 row-cols-1 g-xl-4 g-lg-3 g-3' data-aos="fade-up">
                        <Col>
                            <h6>Get In Touch With AblyWorks</h6>
                            <p>At AblyWorks, we're committed to harnessing technology to foster unity and drive progress. Join us in building a brighter future, where every connection matters.    minima  dolorum fuga perspiciatis</p>
                            <ul>
                                <li>
                                    <i class="fa-solid fa-location-dot"></i>
                                    3480 Preston Ridge Rd Suite 500 Alpharetta GA 30005-2028 US
                                </li>
                                <li>
                                    <i class="fa-solid fa-phone-volume"></i>
                                    <a href="tel:+1(678)772-4824">+1 (678) 772-4824</a>
                                </li>
                                <li>
                                    <i class="fa-solid fa-envelope"></i>
                                    <a href="mailto:hello@ablyworks.com" className='text-lowercase'>hello@ablyworks.com</a>
                                </li>
                            </ul>
                            <div className="socail-links">
                                <ul>
                                    <li>
                                        <a href="https://www.facebook.com/Weablywork" target="_blank"><i class="fa-brands fa-square-facebook"></i></a>
                                    </li>
                                    <li>
                                        <a href="https://www.linkedin.com/company/ablyworks/" target="_blank"><i class="fa-brands fa-linkedin"></i></a>
                                    </li>
                                    <li>
                                        <a href="https://www.instagram.com/ablyworks" target="_blank"><i class="fa-brands fa-square-instagram"></i></a>
                                    </li>
                                    <li>
                                        <a href="https://twitter.com/AblyWorks" target="_blank"><i class="fa-brands fa-square-x-twitter"></i></a>
                                    </li>
                                </ul>
                            </div>
                        </Col>
                        <Col>
                            <h6>Product</h6>
                            <ul>
                                <li><Link to="/aeps">AePS</Link></li>
                                <li><Link to="/hrms">HRMS</Link></li>
                                <li><Link to="/ats">ATS</Link></li>
                            </ul>
                        </Col>
                        <Col>
                            <h6>Quick Links</h6>
                            <ul>
                                <li><Link to="/about">About Us</Link></li>
                                <li><Link to="/contact">Contact</Link></li>
                            </ul>
                        </Col>
                        <Col>
                            <h6>Legal Policy</h6>
                            <ul>
                                <li><Link to="/terms-and-condition">Terms and condition</Link></li>
                                <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                            </ul>
                        </Col>
                    </Row>
                    <hr className='text-secondary' />
                    <p className='m-0 text-center'>© 2024 AblyWorks. All Rights Reserved </p>
                </Container>
            </footer>
        </div>
    )
}

export default Footer