import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';  
import PrivacyHome from '../components/privacy-policy/PrivacyHome';
import PrivacyText from '../components/privacy-policy/PrivacyText';

function PrivacyPolicy() {
  return (
    <Fragment>
       <Helmet>
        <title>Privacy-Policy</title>
        <meta name="description" content="Privacy Policy - Ablyworks" />
        <meta name="keywords" content="Learn about Ablyworks' Surely to safeguarding your personal information. Our Privacy Policy outlines how we collect, use, and protect your data to ensure transparency and trust." />
        <link rel="canonical" href="https://www.ablyworks.com/" />
      </Helmet>
      <PrivacyHome/>
      <PrivacyText/>
    </Fragment>
  )
}

export default PrivacyPolicy
